.File {
  display: flex;
  gap: 8px;
  align-items: center;
}

.FileName {
  color: var(--color-text-level1, #363636);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.FileName.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FileSize {
  flex-shrink: 0;

  overflow: hidden;
  color: var(--color-text-level3, #999);
  text-overflow: ellipsis;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
